/* istanbul ignore file */

/* Temp data api */

import { INCIDENT_TYPES } from '../../common/constants';
import { FIELD_TYPES } from '../../components/fields/fieldTypes';

const { CHECKBOX, PERSON_SEARCH, RADIO, SELECT } = FIELD_TYPES;
const { INJURY_ILLNESS, EVENT, NEAR_MISS } = INCIDENT_TYPES;

// Incident Type
const INCIDENT_TYPE = [
    {
        id: 'crt-q1-type',
        name: 'incidentType',
        externalId: 'incidentType',
        text: '',
        answerTemplate: {
            type: RADIO,
            answerOptions: [
                {
                    answerId: INJURY_ILLNESS,
                    text: 'An injury or illness'
                },
                {
                    answerId: EVENT,
                    text: 'An event (environmental damage or property loss)'
                },
                {
                    answerId: NEAR_MISS,
                    text: 'None of the above, it was a near miss'
                }
            ]
        }
    }
];

const PAPI_INTEGRATED = [
    {
        id: 'crt-injury-alias',
        text: 'Alias of person involved',
        name: 'personId',
        externalId: 'personId',
        placeholder: 'Begin typing to search by alias or full name',
        answerTemplate: {
            type: PERSON_SEARCH
        },
        conditionType: 'AND',
        conditions: [
            {
                field: 'crt-injury-noAliasAvailable',
                value: 'false'
            },
            {
                field: 'crt-q1-type',
                value: INJURY_ILLNESS
            }
        ]
    },
    {
        id: 'crt-injury-noAliasAvailable',
        text: 'The person involved does not have an Amazon alias',
        name: 'noAliasAvailable',
        externalId: 'noAliasAvailable',
        answerTemplate: {
            type: CHECKBOX
        },
        conditions: [
            {
                field: 'crt-q1-type',
                value: INJURY_ILLNESS
            }
        ]
    },
    {
        id: 'crt-q1-cat-1',
        text: 'Type of person involved',
        name: 'employeeType',
        externalId: 'employeeType',
        placeholder: 'Choose a category',
        answerTemplate: {
            type: SELECT,
            answerOptions: [
                {
                    answerId: 'Contractor',
                    text: 'Contractor'
                },
                {
                    answerId: 'Visitor',
                    text: 'Visitor/Community Member'
                }
            ]
        },
        conditionType: 'AND',
        conditions: [
            {
                field: 'crt-q1-type',
                value: INJURY_ILLNESS
            },
            {
                field: 'crt-injury-noAliasAvailable',
                value: 'true'
            }
        ]
    }
];

const INJURY_ILLNESS_CATEGORY = [
    {
        id: 'crt-q1-cat-1',
        text: 'Type of person involved',
        name: 'employeeType',
        externalId: 'employeeType',
        placeholder: 'Choose a category',
        answerTemplate: {
            type: SELECT,
            answerOptions: [
                {
                    answerId: 'Employee',
                    text: 'Amazon employee'
                },
                {
                    answerId: 'Contractor',
                    text: 'Contractor'
                },
                {
                    answerId: 'Visitor',
                    text: 'Visitor/Community Member'
                }
            ]
        },
        conditions: [
            {
                field: 'crt-q1-type',
                value: INJURY_ILLNESS
            }
        ]
    }
];

const EVENT_CATEGORY = [
    {
        id: 'crt-q1-cat-1-event',
        text: 'Type of event',
        name: 'eventType',
        externalId: 'eventType',
        answerTemplate: {
            type: SELECT,
            answerOptions: [
                {
                    answerId: 'Air operations',
                    text: 'Air operations'
                },
                {
                    answerId: 'Drill',
                    text: 'Drill'
                },
                {
                    answerId: 'ENVIRONMENTAL',
                    text: 'Environmental'
                },

                {
                    answerId: 'Fire',
                    text: 'Fire'
                },

                {
                    answerId: 'HIGH_RISK_OPERATIONS',
                    text: 'High risk operations'
                },
                {
                    answerId: 'Natural event',
                    text: 'Natural event'
                },
                {
                    answerId: 'Threatening behavior',
                    text: 'Threatening behavior'
                },
                {
                    answerId: 'Transportation',
                    text: 'Transportation'
                }
            ]
        },
        conditions: [
            {
                field: 'crt-q1-type',
                value: EVENT
            }
        ]
    },
    {
        id: 'crt-q1-cat-2-drill',
        text: 'Sub-type of Event',
        name: 'subEventType',
        externalId: 'subEventType',
        answerTemplate: {
            type: SELECT,
            answerOptions: [
                {
                    answerId: 'Active shooter',
                    text: 'Active shooter'
                },
                {
                    answerId: 'Bomb threat',
                    text: 'Bomb threat'
                },
                {
                    answerId: 'Communicable disease response',
                    text: 'Communicable disease response'
                },
                {
                    answerId:
                        'Fire (evacuation or fire suppression system tests)',
                    text: 'Fire (evacuation or fire suppression system tests)'
                },
                {
                    answerId: 'Nature event (e.g. tornado, snow storm, etc.)',
                    text: 'Nature event (e.g. tornado, snow storm, etc.)'
                }
            ]
        },
        conditions: [
            {
                field: 'crt-q1-cat-1-event',
                value: 'Drill'
            }
        ]
    },
    {
        id: 'crt-q1-cat-2-env',
        text: 'Sub-type of Event',
        name: 'subEventType',
        externalId: 'subEventType',
        answerTemplate: {
            type: SELECT,
            answerOptions: [
                {
                    answerId: 'Spill or release - inside building',
                    text: 'Spill or release - inside building'
                },
                {
                    answerId: 'Spill or release - outside building',
                    text: 'Spill or release - outside building'
                }
            ]
        },
        conditions: [
            {
                field: 'crt-q1-cat-1-event',
                value: 'ENVIRONMENTAL'
            }
        ]
    },
    {
        id: 'crt-q1-cat-2-transport',
        text: 'Sub-type of Event',
        name: 'subEventType',
        externalId: 'subEventType',
        answerTemplate: {
            type: SELECT,
            answerOptions: [
                {
                    answerId: 'Last Mile',
                    text: 'Last Mile'
                },
                {
                    answerId: 'Middle Mile',
                    text: 'Middle Mile'
                },
                {
                    answerId: 'TDR',
                    text: 'TDR and Load Quality'
                },
                {
                    answerId: 'Yard',
                    text: 'Yard'
                }
            ]
        },
        conditions: [
            {
                field: 'crt-q1-cat-1-event',
                value: 'Transportation'
            }
        ]
    },
    {
        id: 'crt-q1-cat-2-high-risk',
        text: 'Sub-type of Event',
        name: 'subEventType',
        externalId: 'subEventType',
        answerTemplate: {
            type: SELECT,
            answerOptions: [
                {
                    answerId: 'Automation',
                    text: 'Automation'
                },
                {
                    answerId: 'Confined spaces',
                    text: 'Confined spaces'
                },
                {
                    answerId: 'Construction, installation, demolition (CID)',
                    text: 'Construction, installation, demolition (CID)'
                },
                {
                    answerId: 'Conveyance',
                    text: 'Conveyance'
                },
                {
                    answerId: 'Electrical',
                    text: 'Electrical'
                },
                {
                    answerId: 'Hot work',
                    text: 'Hot work'
                },
                {
                    answerId: 'Lifting operations',
                    text: 'Lifting operations'
                },
                {
                    answerId: 'Other power equipment',
                    text: 'Other power equipment'
                },
                {
                    answerId: 'PIT',
                    text: 'PIT'
                },
                {
                    answerId: 'Stored energy',
                    text: 'Stored energy'
                },
                {
                    answerId: 'Work at height',
                    text: 'Work at height'
                },
                {
                    answerId: 'Working in isolation',
                    text: 'Working isolation'
                }
            ]
        },
        conditions: [
            {
                field: 'crt-q1-cat-1-event',
                value: 'HIGH_RISK_OPERATIONS'
            }
        ]
    },
    {
        id: 'crt-q1-cat-2-threatening-behavior',
        text: 'Sub-type of Event',
        name: 'subEventType',
        externalId: 'subEventType',
        answerTemplate: {
            type: SELECT,
            answerOptions: [
                {
                    answerId: 'Assault',
                    text: 'Assault'
                },
                {
                    answerId: 'Gun threat or shooting',
                    text: 'Gun threat or shooting'
                },
                {
                    answerId: 'Verbal harrassment',
                    text: 'Verbal harrassment'
                }
            ]
        },
        conditions: [
            {
                field: 'crt-q1-cat-1-event',
                value: 'Threatening behavior'
            }
        ]
    }
];

const AWS_EVENT_CATEGORY = [
    {
        id: 'crt-q1-cat-1-event',
        text: 'Type of event',
        name: 'eventType',
        externalId: 'eventType',
        answerTemplate: {
            type: SELECT,
            answerOptions: [
                {
                    answerId: 'Drill',
                    text: 'Drill'
                },
                {
                    answerId: 'ENVIRONMENTAL',
                    text: 'Environmental'
                },

                {
                    answerId: 'Fire',
                    text: 'Fire'
                },

                {
                    answerId: 'HIGH_RISK_OPERATIONS',
                    text: 'High risk operations'
                },
                {
                    answerId: 'Natural event',
                    text: 'Natural event'
                },
                {
                    answerId: 'Threatening behavior',
                    text: 'Threatening behavior'
                }
            ]
        },
        conditions: [
            {
                field: 'crt-q1-type',
                value: EVENT
            }
        ]
    },
    {
        id: 'crt-q1-cat-2-drill',
        text: 'Sub-type of Event',
        name: 'subEventType',
        externalId: 'subEventType',
        answerTemplate: {
            type: SELECT,
            answerOptions: [
                {
                    answerId: 'Active shooter',
                    text: 'Active shooter'
                },
                {
                    answerId: 'Bomb threat',
                    text: 'Bomb threat'
                },
                {
                    answerId: 'Communicable disease response',
                    text: 'Communicable disease response'
                },
                {
                    answerId:
                        'Fire (evacuation or fire suppression system tests)',
                    text: 'Fire (evacuation or fire suppression system tests)'
                },
                {
                    answerId: 'Nature event (e.g. tornado, snow storm, etc.)',
                    text: 'Nature event (e.g. tornado, snow storm, etc.)'
                }
            ]
        },
        conditions: [
            {
                field: 'crt-q1-cat-1-event',
                value: 'Drill'
            }
        ]
    },
    {
        id: 'crt-q1-cat-2-env',
        text: 'Sub-type of Event',
        name: 'subEventType',
        externalId: 'subEventType',
        answerTemplate: {
            type: SELECT,
            answerOptions: [
                {
                    answerId: 'Spill or release - inside building',
                    text: 'Spill or release - inside building'
                },
                {
                    answerId: 'Spill or release - outside building',
                    text: 'Spill or release - outside building'
                }
            ]
        },
        conditions: [
            {
                field: 'crt-q1-cat-1-event',
                value: 'ENVIRONMENTAL'
            }
        ]
    },
    {
        id: 'crt-q1-cat-2-high-risk',
        text: 'Sub-type of Event',
        name: 'subEventType',
        externalId: 'subEventType',
        answerTemplate: {
            type: SELECT,
            answerOptions: [
                {
                    answerId: 'Confined spaces',
                    text: 'Confined spaces'
                },
                {
                    answerId: 'Construction, installation, demolition (CID)',
                    text: 'Construction, installation, demolition, property damage (CIDP)'
                },
                {
                    answerId: 'Electrical',
                    text: 'Electrical'
                },
                {
                    answerId: 'Hot work',
                    text: 'Hot work'
                },
                {
                    answerId: 'Lifting operations',
                    text: 'Lifting operations'
                },
                {
                    answerId: 'Other power equipment',
                    text: 'Other power equipment'
                },
                {
                    answerId: 'PIT',
                    text: 'PIT'
                },
                {
                    answerId: 'Stored energy',
                    text: 'Stored energy'
                },
                {
                    answerId: 'Work at height',
                    text: 'Work at height'
                },
                {
                    answerId: 'Working in isolation',
                    text: 'Working isolation'
                }
            ]
        },
        conditions: [
            {
                field: 'crt-q1-cat-1-event',
                value: 'HIGH_RISK_OPERATIONS'
            }
        ]
    },
    {
        id: 'crt-q1-cat-2-threatening-behavior',
        text: 'Sub-type of Event',
        name: 'subEventType',
        externalId: 'subEventType',
        answerTemplate: {
            type: SELECT,
            answerOptions: [
                {
                    answerId: 'Assault',
                    text: 'Assault'
                },
                {
                    answerId: 'Gun threat or shooting',
                    text: 'Gun threat or shooting'
                },
                {
                    answerId: 'Verbal harrassment',
                    text: 'Verbal harrassment'
                }
            ]
        },
        conditions: [
            {
                field: 'crt-q1-cat-1-event',
                value: 'Threatening behavior'
            }
        ]
    }
];

export const createIncidentQuestions = [
    ...INCIDENT_TYPE,
    ...INJURY_ILLNESS_CATEGORY,
    ...EVENT_CATEGORY
];

export const createIncidentWithPapiQuestions = [
    ...INCIDENT_TYPE,
    ...PAPI_INTEGRATED,
    ...EVENT_CATEGORY
];

export const awsCreateIncidentQuestions = [
    ...INCIDENT_TYPE,
    ...PAPI_INTEGRATED,
    ...AWS_EVENT_CATEGORY
];
