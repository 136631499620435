var ATTACHMENT = 'ATTACHMENT';
var BODY_MAP = 'BODY_MAP';
var CHECKBOX = 'CHECKBOX';
var CHECKLIST = 'CHECKLIST';
var DATE = 'DATE';
var DATE_RANGE = 'DATE_RANGE';
var DATE_TIME = 'DATE_TIME';
var EXPANSION_LIST = 'EXPANSION_LIST';
var HIDDEN_FIELD = 'HIDDEN';
var INCIDENT_LINK = 'INCIDENT_LINK';
var MULTIPLE_PRODUCT = 'MULTIPLE_PRODUCT';
var NON_AMAZONIAN = 'NON_AMAZONIAN';
var PERSON = 'PERSON';
var PERSON_SEARCH = 'PERSON_SEARCH';
var PROCESS_PATH_SELECT = 'PROCESS_PATH_SELECT';
var PRODUCT_FIELD = 'PRODUCT';
var RADIO = 'RADIO';
var READ_ONLY = 'readOnly';
var RISK_ASSESSMENT_SELECT = 'RISK_ASSESSMENT_SELECT';
var SELECT = 'DROPDOWN_LIST';
var SLIDER = 'SLIDER';
var SITE_CONFIG_ATTRIBUTE_SELECT = 'SITE_CONFIG_ATTRIBUTE_SELECT';
var BUTTON_GROUP = 'BUTTON_GROUP';
var TENURE = 'TENURE';
var TEXT_AREA = 'TEXT_AREA';
var TEXT_FIELD = 'TEXT';
var TIME = 'TIME';
export var FIELD_TYPES = {
    ATTACHMENT: ATTACHMENT,
    BODY_MAP: BODY_MAP,
    BUTTON_GROUP: BUTTON_GROUP,
    CHECKBOX: CHECKBOX,
    CHECKLIST: CHECKLIST,
    DATE: DATE,
    DATE_RANGE: DATE_RANGE,
    DATE_TIME: DATE_TIME,
    EXPANSION_LIST: EXPANSION_LIST,
    HIDDEN_FIELD: HIDDEN_FIELD,
    INCIDENT_LINK: INCIDENT_LINK,
    MULTIPLE_PRODUCT: MULTIPLE_PRODUCT,
    NON_AMAZONIAN: NON_AMAZONIAN,
    PERSON: PERSON,
    PERSON_SEARCH: PERSON_SEARCH,
    PROCESS_PATH_SELECT: PROCESS_PATH_SELECT,
    PRODUCT_FIELD: PRODUCT_FIELD,
    RADIO: RADIO,
    READ_ONLY: READ_ONLY,
    RISK_ASSESSMENT_SELECT: RISK_ASSESSMENT_SELECT,
    SELECT: SELECT,
    SLIDER: SLIDER,
    SITE_CONFIG_ATTRIBUTE_SELECT: SITE_CONFIG_ATTRIBUTE_SELECT,
    TENURE: TENURE,
    TEXT_AREA: TEXT_AREA,
    TEXT_FIELD: TEXT_FIELD,
    TIME: TIME
};
